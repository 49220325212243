@font-face {
    font-family: 'Gill-Sans';
    src: url('../assets/fonts/Gill-Sans/GillSans.woff2') format('woff2'),
    url('../assets/fonts/Gill-Sans/GillSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('../assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sassoon Sans Std';
    src: url('../assets/fonts/Sassoon/SassoonSansStd.woff2') format('woff2'),
    url('../assets/fonts/Sassoon/SassoonSansStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sassoon Infant Std';
    src: url('../assets/fonts/Sassoon/SassoonInfantStd.woff2') format('woff2'),
    url('../assets/fonts/Sassoon/SassoonInfantStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
