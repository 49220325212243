.btn-header {
    width: 167px;
    height: 36px;
    top: 70px;
    left: 1123px;
    padding: 8px 12px 4px 12px;
    border-radius: 30px;
    gap: 10px;
    font-family: Sassoon Sans Std, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}
img.card-img-top {
    border: 10px solid #ebebeb;
    border-radius: 15%;
}

.button-group button {
    margin-bottom: 5px;
    border: transparent;
}

.nav-link {
    color: black !important;
    font-family: "Gill-Sans", sans-serif;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.nav-link:hover {
    color: #88C62C !important;
}


/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
    .secondary-links {
        margin-right: 3em;
    }

    .header-link-row {
        margin-right: 5vw;
        margin-left: 5vw;
    }

    .button-group {
        margin-left: 2vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
    .secondary-links {
        /*margin-left: 0;*/
    }

    .secondary-links {
        /*margin-right: 3em;*/
    }

    .header-link-row {
        /*margin-right: 1vw;*/
        /*margin-left: 1vw;*/
    }

    .social-links-header {
        /*margin-right: 1vw;*/
        /*margin-left: 1vw;*/
    }

    .button-group {
        /*margin-left: 2vw;*/
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1300px) {
    .secondary-links {
        /*margin-right: 3em;*/
    }

    .header-link-row {
        /*margin-right: 3vw;*/
        /*margin-left: 3vw;*/
    }

    .button-group {
        /*margin-left: 2vw;*/
    }

    .social-links-header {
        /*margin-right: 3vw;*/
        /*margin-left: 3vw;*/
    }

    .button-group {
        /*margin-left: 60px;*/
    }
}

/* Custom breakpoint at 1300px */
@media (min-width: 1300px) and (max-width: 1400px) {
    .secondary-links {
        margin-right: 3em;
    }

    .header-link-row {
        margin-right: 3vw;
        margin-left: 3vw;
    }

    .social-links-header {
        margin-right: 7vw;
        margin-left: 7vw;
    }

    .button-group {
        margin-left: 2vw;
    }
}

/* Custom breakpoint at 1400px and up */
@media (min-width: 1400px) {
    .secondary-links {
        margin-right: 3em;
    }

    .header-link-row {
        margin-right: 5vw;
        margin-left: 10vw;
    }

    .social-links-header {
        margin-right: 8vw;
        margin-left: 8vw;
    }

    .button-group {
        margin-left: 2vw;
    }
}
