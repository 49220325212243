.mobile-header .navbar-nav .nav-link {
    color: white !important;
    font-family: "Gill-Sans", sans-serif !important;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    padding: 10px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.mobile-header .navbar-collapse {
    background-color: #88C62C; /* Set the collapsed navigation background color to green */
    color: white !important;
}

.mobile-header .navbar-nav .nav-link.active {
    background-color: white; /* Set the background color of the active link to white */
    color: #F57A1E !important; /* Set the font color of the active link to #F57A1E */
}

/* mobile-header.css */
.mobile-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff; /* change to the color that fits your design */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 157px; /* Set the height of your header here */
}
