/* iPhone */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 480px) {
    /* Styles for general iPhone here */
    /* Styles for iPhone 6/7/8/X here */
    .footer-logo {
        width: 181px;
       
        flex-shrink: 0;
        margin-top: 38px;
        margin-bottom: 34px;
    }

    .links-column {
        text-align: left;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .email_address {
        padding-bottom: 28px !important;
    }

    .footer-copyright {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 9.45px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 211.64% */
        text-align: left;
    }
}

/* iPhone 6/7/8/X */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px) {
    /* Styles for iPhone 6/7/8/X here */
    .footer-logo {
        width: 181px;
        flex-shrink: 0;
        margin-top: 38px;
        margin-bottom: 34px;
    }

    .links-column {
        text-align: left;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .email_address {
        padding-bottom: 28px !important;
    }

    .footer-copyright {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 9.45px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 211.64% */
        text-align: left;
    }
}

/* iPhone XR / iPhone 11 */
@media only screen
and (min-device-width: 414px)
and (max-device-width: 896px) {
    /* Styles for iPhone XR / iPhone 11 here */
    .footer-logo {
        width: 181px;
        flex-shrink: 0;
        margin-top: 38px;
        margin-bottom: 34px;
    }


    .links-column {
        text-align: left;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .email_address {
        padding-bottom: 28px !important;
    }

    .footer-copyright {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 9.45px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 211.64% */
        text-align: left;
    }
}

/* iPad and iPad Pro */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px) {
    /* Styles for general iPads here */
    /* Styles for iPad Pro 12.9" here */
    /* Styles for Desktop here */
    /* Styles for Desktop here */
    .footer-logo {
        width: 243px;
       
        flex-shrink: 0;
        margin-bottom: 22px;
    }

    .footerRow {
        margin-top: 50px
    }

    .links-column {
        text-align: left;
        margin-top: 133px !important;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .link-text {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .list-group-item.other_phone {
        padding-bottom: 29px;
    }

    .address_label {
        padding-bottom: 10px !important;
    }


}

/* iPad Pro 12.9" */
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px) {
    /* Styles for iPad Pro 12.9" here */
    /* Styles for Desktop here */
    /* Styles for Desktop here */
    .links-column {
        padding-left: 55px;
    }

    .footer-logo {
        width: 243px;
       
        flex-shrink: 0;
        margin-bottom: 22px;
    }

    .footerRow {
        /*margin-left: 152px;*/
        margin-top: 50px
    }

    .links-column {
        text-align: left;
        margin-top: 96px !important;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .link-text {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .list-group-item.other_phone {
        padding-bottom: 29px;
    }

    .address_label {
        padding-bottom: 10px !important;
    }

    .footer-copyright {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 11.11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 180.018% */
    }
}

/* MacBook */
@media only screen
and (min-width: 1200px)
and (max-width: 1920px) {
    /* Styles for Desktop here */
    .footer-logo {
        width: 243px;
     
        flex-shrink: 0;
        margin-bottom: 22px;
    }

    .footerRow {
        margin-left: 152px;
        margin-top: 50px
    }

    .links-column {
        text-align: left;
        margin-top: 96px !important;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .link-text {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .list-group-item.other_phone {
        padding-bottom: 29px;
    }

    .address_label {
        padding-bottom: 10px !important;
    }

    .footer-copyright {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 11.11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 180.018% */
    }
}

/* Desktop */
@media only screen
and (min-width: 1920px) {
    /* Styles for Desktop here */
    .footer-logo {
        width: 243px;
         
        flex-shrink: 0;
        margin-bottom: 22px;
    }

    .footerRow {
        margin-left: 152px;
        margin-top: 50px
    }

    .links-column {
        text-align: left;
        margin-top: 96px !important;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
    }

    .link-text {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
    }

    .link-black-hover {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.5px; /* 203.571% */
    }

    .list-group-item {
        margin-bottom: 0;
        padding-top: 8px;
        color: #272727;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .list-group-item.other_phone {
        padding-bottom: 29px;
    }

    .address_label {
        padding-bottom: 10px !important;
    }

    .footer-copyright {
        color: #000;
        font-family: Montserrat, serif;
        font-size: 11.11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 180.018% */
    }
}

.logo-column {
    text-align: left;
}


.link-black-hover:hover {
    color: #88C62C;
}
